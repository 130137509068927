<template>
  <section class="pb-2">
    <e-filters
      ref="filters"
      :title="$t('Filtros')"
      :searching="fetching"
      :show-search-button="false"
      @reset="resetFiltersLocal"
    >
      <template #after_buttons>
        <e-button
          id="download"
          class="ml-1 btn-sm-block"
          variant="primary"
          icon="download"
          :busy="fetching"
          :text="$t('Baixar Relatório')"
          @click="downloadReport"
        />
        <!-- Button to download report (hidden by default) -->
        <a
          ref="downloadLink"
          style="display: none"
        />
      </template>
      <div>
        <b-row>
          <b-col md="4">
            <e-store-combo
              v-model="filtersToReport.storeId"
              :required="false"
              :placeholder="$t('Todas')"
            />
          </b-col>
          <b-col md="4">
            <e-person-search
              id="client"
              v-model="filtersToReport.customerId"
              :label="$t('Cliente')"
              is-customer
              :only-active="false"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="description"
              v-model="filtersToReport.description"
              :label="$t('Descrição')"
              :placeholder="$t('Descrição')"
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              id="sale-id"
              v-model="filtersToReport.saleId"
              :label="$t('Id da venda')"
              type="text-number"
              :precision="0"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="value"
              v-model="filtersToReport.value"
              :label="$t('Valor')"
              type="text-number"
              currency="R$"
              :precision="2"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="status"
              v-model="filtersToReport.paymentStatus"
              type="vue-select"
              :label="$t('Status')"
              :placeholder="$t('Todos')"
              :options="statusTypes"
            />
          </b-col>
          <b-col md="4">
            <e-payment-method
              id="payment_method"
              v-model="filtersToReport.paymentMethods"
              multiple
              :placeholder="$t('Todos')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="expense-period_type"
              v-model="filtersToReport.periodType"
              type="vue-select"
              :label="$t('Tipo do período')"
              :options="receivablePeriodTypeOptions()"
              :clearable="false"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="expense-period"
              v-model="filtersToReport.period"
              type="date-range-picker"
              :label="$t('Período')"
              :time-picker="false"
              opens="left"
              class="required"
              validation="required"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { EFilters, EStoreCombo, EPersonSearch, EButton, EPaymentMethod } from '@/views/components'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import { financialDomain, formulateValidation } from '@/mixins'

export default {
  components: {
    BRow,
    BCol,
    EFilters,
    EStoreCombo,
    EPaymentMethod,
    EPersonSearch,
    EButton,
  },

  mixins: [formulateValidation, financialDomain],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapState('pages/financial/receivables', ['filtersToReport', 'reportData']),
    statusTypes() {
      return [
        { value: 'isPaid', label: this.$t('Pago') },
        { value: 'isPending', label: this.$t('Pendente') },
        { value: 'isLate', label: this.$t('Em atraso') },
      ]
    },
  },

  mounted() {
    this.fetchCheckingAccounts()
  },

  methods: {
    ...mapActions('pages/financial/receivables', ['generateReport', 'cleanFilterToReport']),
    ...mapActions('app', ['fetchCheckingAccounts']),
    ...mapActions('common/clients', {
      stSearchClients: 'searchClients',
    }),

    resetFiltersLocal() {
      this.cleanFilterToReport()
    },

    async downloadReport() {
      try {
        if (this.$refs.filters.isValid()) {
          this.fetching = true
          await this.generateReport()
          // Get the download link element from the template
          const { downloadLink } = this.$refs

          if (this.reportData) {
            // Set the URL and download attributes of the link
            downloadLink.href = this.reportData
            downloadLink.download = `Contas_a_Receber_${moment().format()}.xlsx`

            // Click the link to start the download
            downloadLink.click()
          } else {
            this.showError({ message: this.$t('Não foi possível recuperar os dados do relatório') })
          }
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
  },
}
</script>

<style lang="scss">
.receivable-select-row {
  & table tbody .payment-pending td {
    cursor: pointer !important;
  }
}
.check-td {
  padding-left: 14px !important;
}
</style>
