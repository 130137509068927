var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "pb-2" },
    [
      _c(
        "e-filters",
        {
          ref: "filters",
          attrs: {
            title: _vm.$t("Filtros"),
            searching: _vm.fetching,
            "show-search-button": false,
          },
          on: { reset: _vm.resetFiltersLocal },
          scopedSlots: _vm._u([
            {
              key: "after_buttons",
              fn: function () {
                return [
                  _c("e-button", {
                    staticClass: "ml-1 btn-sm-block",
                    attrs: {
                      id: "download",
                      variant: "primary",
                      icon: "download",
                      busy: _vm.fetching,
                      text: _vm.$t("Baixar Relatório"),
                    },
                    on: { click: _vm.downloadReport },
                  }),
                  _c("a", {
                    ref: "downloadLink",
                    staticStyle: { display: "none" },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-store-combo", {
                        attrs: {
                          required: false,
                          placeholder: _vm.$t("Todas"),
                        },
                        model: {
                          value: _vm.filtersToReport.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filtersToReport, "storeId", $$v)
                          },
                          expression: "filtersToReport.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-person-search", {
                        attrs: {
                          id: "client",
                          label: _vm.$t("Cliente"),
                          "is-customer": "",
                          "only-active": false,
                        },
                        model: {
                          value: _vm.filtersToReport.customerId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filtersToReport, "customerId", $$v)
                          },
                          expression: "filtersToReport.customerId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "description",
                          label: _vm.$t("Descrição"),
                          placeholder: _vm.$t("Descrição"),
                        },
                        model: {
                          value: _vm.filtersToReport.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.filtersToReport, "description", $$v)
                          },
                          expression: "filtersToReport.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "sale-id",
                          label: _vm.$t("Id da venda"),
                          type: "text-number",
                          precision: 0,
                        },
                        model: {
                          value: _vm.filtersToReport.saleId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filtersToReport, "saleId", $$v)
                          },
                          expression: "filtersToReport.saleId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "value",
                          label: _vm.$t("Valor"),
                          type: "text-number",
                          currency: "R$",
                          precision: 2,
                        },
                        model: {
                          value: _vm.filtersToReport.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.filtersToReport, "value", $$v)
                          },
                          expression: "filtersToReport.value",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "status",
                          type: "vue-select",
                          label: _vm.$t("Status"),
                          placeholder: _vm.$t("Todos"),
                          options: _vm.statusTypes,
                        },
                        model: {
                          value: _vm.filtersToReport.paymentStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.filtersToReport, "paymentStatus", $$v)
                          },
                          expression: "filtersToReport.paymentStatus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-payment-method", {
                        attrs: {
                          id: "payment_method",
                          multiple: "",
                          placeholder: _vm.$t("Todos"),
                        },
                        model: {
                          value: _vm.filtersToReport.paymentMethods,
                          callback: function ($$v) {
                            _vm.$set(_vm.filtersToReport, "paymentMethods", $$v)
                          },
                          expression: "filtersToReport.paymentMethods",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "expense-period_type",
                          type: "vue-select",
                          label: _vm.$t("Tipo do período"),
                          options: _vm.receivablePeriodTypeOptions(),
                          clearable: false,
                        },
                        model: {
                          value: _vm.filtersToReport.periodType,
                          callback: function ($$v) {
                            _vm.$set(_vm.filtersToReport, "periodType", $$v)
                          },
                          expression: "filtersToReport.periodType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "expense-period",
                          type: "date-range-picker",
                          label: _vm.$t("Período"),
                          "time-picker": false,
                          opens: "left",
                          validation: "required",
                        },
                        model: {
                          value: _vm.filtersToReport.period,
                          callback: function ($$v) {
                            _vm.$set(_vm.filtersToReport, "period", $$v)
                          },
                          expression: "filtersToReport.period",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }